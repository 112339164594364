<template>
  <div>
    <CSpinner v-if="$apollo.queries.contract.loading" color="info"/>
    <CAlert
        v-if="error"
        color="danger"
        closeButton
        @update:show="removeError">
      {{error}}
    </CAlert>
    <DeleteModal
        entity="contract"
        :name="itemName"
        :nid="contract.nid"
        :show.sync="showDeleteItemModal"
        v-on:delete="deleteItem"
        v-on:cancel-delete="toggleDeleteItemModal"
    />
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol class="text-left" sm="2">
            <DetailsButtonGroup
                v-on:save="saveItem"
                v-on:cancel="resetItem"
                v-on:delete-prompt="toggleDeleteItemModal"
                v-on:close="$router.push({path: '/contracts/contracts'})"
            />
          </CCol>
          <CCol sm="8">
            <h3><span v-html="entityIcon"></span>{{itemName}}</h3>
          </CCol>
          <CCol sm="2">
            <CButton
                class="btn-validation"
                :color="contractSave.isValid ? 'success': 'info'"
                :variant="contractSave.isValid ? null: 'outline'"
                @click="toggleIsValid"
              >
              {{this.contractSave.isValid ? 'Valid': 'Validate'}}
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <ContractForm
            v-bind:contractIn="contractEdited"
            v-on:update-item="updateItem"
            v-on:error="catchError"
        />
      </CCardBody>
      <CCardFooter
          class="text-left">
        <CRow>
          <CCol sm="2">
            <DetailsButtonGroup
                v-on:save="saveItem"
                v-on:cancel="resetItem"
                v-on:delete-prompt="toggleDeleteItemModal"
                v-on:close="$router.push({ path: '/contracts/contracts'})"
            />
          </CCol>
          <CCol sm="8" />
          <CCol sm="2">
            <CButton
                class="btn-validation"
                :color="contractSave.isValid ? 'success': 'info'"
                :variant="contractSave.isValid ? null: 'outline'"
                @click="toggleIsValid"
            >
              {{this.contractSave.isValid ? 'Valid': 'Validate'}}
            </CButton>
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import gql from "graphql-tag";
import DetailsButtonGroup from "@/components/common/DetailsButtonGroup";
import DeleteModal from "@/components/common/DeleteModal";
import ContractForm from "@/apps/contracts/components/ContractForm";

const queryContract = gql`
query contract($nid: ID!) {
  contract(nid: $nid){
    nid
    client{
      nid
      name
      accountOwner{
        nid
        firstName
      }
    }
    entity
    domain
    signingDate
    startTerm
    endTerm
    startContract
    endContract
    positions{
      nid
      numberOfItems
      product{
        nid,
        name
      }
      startTerm
      endTerm
      pricePerItem
      ppcRate
      ppcAmount
      isReimbursement
      isDiscount
      invoiceCycle
      notes
    }
    automaticContractRenewal
    cancellationPeriod
    specialCancellationRight
    cancellationReceivedDate
    invoiceDate
    paymentTarget
    sepa
    link
    isValid
  }
}
`
const updateContract = gql`
  mutation updateContract($nid: ID!, $contract: ContractInput!){
    updateContract(nid: $nid, contract: $contract){
        nid
    }
  }
`
const deleteContract = gql`
  mutation deleteContract($nid: ID!){
    deleteContract(nid: $nid)
  }
`
const clonedeep = require('lodash.clonedeep')

export default {
  name: "Contract",
  components: {
    DetailsButtonGroup,
    DeleteModal,
    ContractForm
  },
  data() {
    return {
      error: null,
      showDeleteItemModal: false,
      contract: {},
      contractEdited: {},
      contractSave: {}
    }
  },
  methods: {
    updateItem(data){
      //create neuraum references from multiselect objects
      data.client = data.client? {nid: data.client.nid}: null;
      data.positions = data.positions.map(item => {
        delete item.idx
        item.product = {nid: item.product.nid}
        //change data types
        item.numberOfItems = item.numberOfItems!==undefined && item.numberOfItems!==null? parseFloat(item.numberOfItems): null;
        item.pricePerItem = item.pricePerItem!==undefined && item.pricePerItem!==null? parseFloat(item.pricePerItem): null;
        item.ppcRate = item.ppcRate!==undefined && item.ppcRate!==null? parseFloat(item.ppcRate): null;
        item.pricePerItem = item.pricePerItem!==undefined && item.pricePerItem!==null? parseFloat(item.pricePerItem): null;
        item.ppcAmount = item.ppcAmount!==undefined && item.ppcAmount!==null? parseFloat(item.ppcAmount): null;
        return item
      });

      this.contractSave = data;
      // Changed contracts are set back to not-validated
      this.contractSave.isValid = false;
      this.contractEdited.isValid = false;
    },
    removeError(hide){
      if(!hide){
        this.error = null
      }
    },
    catchError(event){
      this.error = event
    },
    saveItem(){
      const {nid, __typename, ...updatedObj} = this.contractSave;
      if(updatedObj.positions){
        updatedObj.positions = updatedObj.positions.map(({__typename, ...keepAttrs}) => keepAttrs)
      }
      this.$apollo.mutate({
        mutation: updateContract,
        variables: {
          nid: nid,
          contract: updatedObj
        }
      })
      .then(res => {
        this.$emit('save-item', this.contractEdited);
        this.$store.commit('set', ['addModalShow', false]);
        this.$store.commit('set', ['refetchData', true]);
        this.$router.push({ path: '/contracts/contracts'});
      })
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
    },
    toggleDeleteItemModal(){
      this.showDeleteItemModal = !this.showDeleteItemModal;
    },
    resetItem(){
      this.contractEdited = clonedeep(this.$apolloData.data.contract);
    },
    deleteItem(){
      this.$apollo.mutate({
        mutation: deleteContract,
        variables: {
          nid: this.contract.nid
        }
      })
      .then(res => {
        this.$emit('delete-contract', this.contract.nid);
        this.$store.commit('set', ['refetchData', true]);
        this.$router.push({ path: '/contracts/contracts'});
      })
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
    },
    toggleIsValid(){
      if(this.allowEdit){
        this.contractEdited.isValid = ! this.contractEdited.isValid;
        this.contractSave.isValid = ! this.contractSave.isValid;
      }
    },
  },
  computed: {
    allowEdit() {
      return this.$store.state.allowEdit
    },
    itemName(){
      return this.contractEdited.client?.name
    },
    entityIcon(){
      switch (this.contractEdited.entity) {
        case 'PW':
          return '<div class="badge-pw">PW</div>'
        case 'MS':
          return '<div class="badge-ms">MS</div>'
        case 'IM':
          return '<div class="badge-im">IM</div>'
        default:
          return '<div class="badge-pw">PW</div>'
      }
    }
  },
  apollo: {
    contract: {
      query: queryContract,
      error(error) {
        this.error = error.message;
      },
      variables() {
        return {
          nid: this.$route.params.nid
        }
      },
      result ({ data, loading, networkStatus }) {
        this.resetItem();
      },
      update(data){
        data.contract.positions=data.contract.positions.map((item, idx) => { return {...item, idx}})
        return data.contract
      },
      fetchPolicy: "cache-and-network"
    }
  }
}
</script>

<style scoped>
.btn-validation{
 width: 100%;
}
>>> .badge-ms {
  background-color: #9c0725 !important;
  white-space: normal !important;
  color: #fff !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

>>> .badge-pw {
  background-color: #0083aa !important;
  white-space: normal !important;
  color: #fff !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

>>> .badge-im {
  background-color: #fc0 !important;
  white-space: normal !important;
  color: #303038 !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
